
import { RouteRecord } from "vue-router";
import RouteExtractor from "@/assets/scripts/RouteExtractor";

export default {
  name: "Breadcrumb",
  data() {
    return {
      currentRoute: (this as any).$route as RouteRecord,
      routes: RouteExtractor.getBreadcrumbRoutes(
        (this as any).$router.getRoutes(),
        (this as any).$route
      ) as RouteRecord[],
    };
  },
};
