
    export default {
        name: "BannerLayout",
        props: {
            slogan: String,
            subtitle: String,
            backgroundImage: String,
        },
        methods: {
            setBackground(): void {
                if ((this as any).backgroundImage != null) {
                    (this as any).$refs.banner.style.backgroundImage
                        = "url('" + (this as any).backgroundImage + "')";
                    (this as any).$refs.banner.style.textShadow
                        = "var(--outline-text-shadow)";
                }
            },
        },
        mounted() {
            (this as any).setBackground();
        },
    };
