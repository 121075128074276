class ImageLoader {
    public static loadImageSrc(path: string): string {
      try {
        return require(`@/${path}`);
      } catch(error) {
        console.warn(error.message);
        return this.loadImagePlaceholder();
      }
    }

    private static loadImagePlaceholder(): string {
        try {
          return require("@/assets/images/decorators/placeholder.png");
        } catch(error) {
          throw(error.message);
        }
      }
  }
  
  export default ImageLoader;
  