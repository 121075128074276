
  import Axios from "axios";
  import HeaderLayout from "@/components/layouts/Header.vue";
  import FooterLayout from "@/components/layouts/Footer.vue";
  import SingleBlock from "@/components/blocks/Single.vue";
  import WarningAlert from "@/components/alerts/Warning.vue";

  export default {
    name: "ZoekresultatenView",
    components: {
      HeaderLayout,
      FooterLayout,
      SingleBlock,
      WarningAlert,
    },
  }
