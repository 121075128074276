
  import { RouteRecord } from "vue-router";
  import RouteExtractor from "@/assets/scripts/RouteExtractor";
  import Node from "@/components/sitemap/components/Node.vue";

  export default {
    name: "Sitemap",
    components: {
        Node,
    },
    methods: {
        getIndexRoute(): RouteRecord {
            const index = "/";

            for (let i = 0; i < (this as any).routes.length; i ++) {
                if ((this as any).routes[i].path == index) {
                    return (this as any).routes[i];
                }
            }

            return (this as any).$route;
        },
        getSitemapRoutes(): RouteRecord[] {
            return RouteExtractor.getSitemapRoutes((this as any).$router.getRoutes());
        },
    },
    data() {
        return {
            routes: (this as any).getSitemapRoutes(),
        }
    },
  };
