import { Router, RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import Index from "@/views/Index.vue";
import Contact from "@/views/contact/Contact.vue";
import Nieuws from "@/views/nieuws/Nieuws.vue";
import Porfolio from "@/views/portfolio/Portfolio.vue";
import Dochterondernemingen from "@/views/dochterondernemingen/Dochterondernemingen.vue";
import Zoekresultaten from "@/views/zoekresultaten/Zoekresultaten.vue";
import AlgemeneVoorwaarden from "@/views/algemeneVoorwaarden/AlgemeneVoorwaarden.vue";
import Sitemap from "@/views/sitemap/Sitemap.vue";
import PageNotFound from "@/views/errors/PageNotFound.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "ComputerInfor",
        component: Index,
        meta: {
            title: "ComputerInfor",
        },
    },
    {
        path: "/nieuws",
        name: "Nieuws",
        component: Nieuws,
        meta: {
            title: "Nieuws",
        },
    },
    {
        path: "/dochterondernemingen",
        name: "Dochterondernemingen",
        component: Dochterondernemingen,
        meta: {
            title: "Dochterondernemingen",
        },
    },
    {
        path: "/portfolio",
        name: "Portfolio",
        component: Porfolio,
        meta: {
            title: "Portfolio",
        },
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
            title: "Contact",
        },
    },
    {
        path: "/algemene_voorwaarden",
        name: "AlgemeneVoorwaarden",
        component: AlgemeneVoorwaarden,
        meta: {
            title: "Algemene voorwaarden",
            hideInNavigator: true,
        },
    },
    {
        path: "/zoekresultaten",
        name: "Zoekresultaten",
        component: Zoekresultaten,
        meta: {
            title: "Zoekresultaten",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
    {
        path: "/sitemap",
        name: "Sitemap",
        component: Sitemap,
        meta: {
            title: "Sitemap",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
    {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound,
        meta: {
            title: "Pagina niet gevonden",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
];

const router: Router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach((to) => {
    if (to.meta.title == null) {
        document.title = "ComputerInfor | Goed geïnformeerd";
    } else {
        let appendix: string;

        if (to.fullPath == "/") {
            appendix = " | Goed geïnformeerd";
        } else {
            appendix = " | ComputerInfor";
        }

        document.title = to.meta.title as string + appendix;
    }
});

export default router;
