
import HeaderLayout from "@/components/layouts/Header.vue";
import FooterLayout from "@/components/layouts/Footer.vue";
import QuadrupleBlock from "@/components/blocks/Quadruple.vue";

export default {
  name: "NieuwsView",
  components: {
    HeaderLayout,
    FooterLayout,
    QuadrupleBlock,
  },
};
