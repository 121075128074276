import Axios, { AxiosInstance } from "axios";

class ContactClient {
    #client: AxiosInstance;

    constructor() {
        this.#client = Axios.create({
            baseURL: "https://form-service.computerinfor.com",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    async postForm(name: string, mail: string, message: string): Promise<boolean> {
        let isPosted = false;
        
        await this.#client.post("/form?name=computerinfor.nl", {
            name: name,
            mail: mail,
            subject: "Contactformulier",
            message: message,
        }).then((response) => {
            if (response.status == 200) {
                isPosted = true;
            }

            console.log(response);
        }, (error) => {
            console.log(error);
        });

        return isPosted;
    }
}

export default ContactClient;
