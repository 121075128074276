import { RouteRecord } from "vue-router";

class RouteExtractor {
    private static SEPARATOR = "/";

    private static getPaths(path: string): string[] {
        if (path == null || path == "") {
            return [];
        }

        const paths: string[] = [this.SEPARATOR];
        const pathSegments: string[] = path.split(this.SEPARATOR);
        let pathBuffer = "";

        for (let i = 0; i < pathSegments.length; i ++) {
            if (pathSegments[i] != "") {
                pathBuffer += this.SEPARATOR + pathSegments[i];
                paths.push(pathBuffer);
            }
        }

        return paths;
    }

    private static isChildPath(childPath: string, parentPath: string) {
        const childPaths: string[] = this.getPaths(childPath);
        const parentPaths: string[] = this.getPaths(parentPath);

        // Child needs to be a direct descendant of parent.
        if (childPaths.length - parentPaths.length == 1) {
            childPaths.pop();

            for (let i = 0; i < parentPaths.length; i ++) {
                if (childPaths[i] != parentPaths[i]) {
                    return false;
                }
            }

            return true;
        }

        return false;
    }

    public static getRoutesByLevel(routes: RouteRecord[], level: number): RouteRecord[] {
        if (routes == null) {
            return [];
        }

        const extractedRoutes: RouteRecord[] = [];

        if (level == 1) {
            for (let i = 0; i < routes.length; i ++) {
                if (routes[i].path == this.SEPARATOR) {
                    extractedRoutes.push(routes[i]);
                    break;
                }
            }
        } else {
            for (let i = 0; i < routes.length; i ++) {
                if (routes[i].path.split(this.SEPARATOR).length == level && routes[i].path != this.SEPARATOR) {
                    extractedRoutes.push(routes[i]);
                }
            }
        }

        return extractedRoutes;
    }

    public static getChildRoutes(route: RouteRecord, routes: RouteRecord[]): RouteRecord[] {
        if (route == null || routes == null || routes.length == 0) {
            return [];
        }

        const extractedRoutes: RouteRecord[] = [];

        for (let i = 0; i < routes.length; i ++) {
            if (this.isChildPath(routes[i].path, route.path)) {
                extractedRoutes.push(routes[i]);
            }
        }

        return extractedRoutes;
    }

    public static getNavigatorRoutes(routes: RouteRecord[]): RouteRecord[] {
        if (routes == null || routes.length == 0) {
            return [];
        }

        const extractedRoutes: RouteRecord[] = routes;

        for (let i = 0; i < extractedRoutes.length; i ++) {
            if (extractedRoutes[i].meta.hideInNavigator) {
                extractedRoutes.splice(i, 1);
                i = 0;
            }
        }

        return extractedRoutes;
    }

    public static getBreadcrumbRoutes(routes: RouteRecord[], currentRoute: RouteRecord): RouteRecord[] {
        if (routes == null || routes.length == 0 || currentRoute == null) {
            return [];
        }

        const extractedRoutes: RouteRecord[] = [];
        const paths: string[] = this.getPaths(currentRoute.path);

        for (let i = 0; i < paths.length; i ++) {
            for (let j = 0; j < routes.length; j ++) {
                if (routes[j].path == paths[i]) {
                    extractedRoutes.push(routes[j]);
                }
            }
        }

        return extractedRoutes;
    }

    public static getSitemapRoutes(routes: RouteRecord[]): RouteRecord[] {
        if (routes == null || routes.length == 0) {
            return [];
        }

        const extractedRoutes: RouteRecord[] = [];

        // Extract sitemap routes.
        for (let i = 0; i < routes.length; i ++) {
            if (!routes[i].meta.hideInSitemap) {
                extractedRoutes.push(routes[i]);
            }
        }

        return extractedRoutes;
    }
}

export default RouteExtractor;
