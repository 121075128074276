
import { RouteRecord } from "vue-router";

export default {
  name: "NavigatorElement",
  props: {
    label: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentRoute: (this as any).$route as RouteRecord,
    };
  },
};
