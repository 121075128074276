import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34a9956c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "banner",
  class: "banner-section"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString($props.slogan), 1),
      ($props.subtitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.subtitle), 1))
        : _createCommentVNode("", true)
    ])
  ], 512))
}