
import ImageLoader from "@/assets/scripts/ImageLoader";
import HeaderLayout from "@/components/layouts/Header.vue";
import FooterLayout from "@/components/layouts/Footer.vue";
import DoubleBlock from "@/components/blocks/Double.vue";
import PageButton from "@/components/buttons/Page.vue";

export default {
  name: "DochterondernemingenView",
  components: {
    HeaderLayout,
    FooterLayout,
    DoubleBlock,
    PageButton,
  },
  data() {
    return {
      maatontwikkelingImage: ImageLoader.loadImageSrc("assets/images/decorators/code.jpg"),
      maatcomputersImage: ImageLoader.loadImageSrc("assets/images/decorators/desktop_custom.jpg"),
    };
  },
};
