
  import ImageLoader from "@/assets/scripts/ImageLoader";
  import HeaderLayout from "@/components/layouts/Header.vue";
  import FooterLayout from "@/components/layouts/Footer.vue";
  import SingleBlock from "@/components/blocks/Single.vue";
  import TripleBlock from "@/components/blocks/Triple.vue";
  import NoticeAlert from "@/components/alerts/Notice.vue";
  import ContactDetailsTable from "@/components/tables/ContactDetails.vue";
  import OpeningHoursTable from "@/components/tables/OpeningHours.vue";
  import ContactForm from "@/components/forms/Contact.vue";

  export default {
    name: "ContactView",
    components: {
      HeaderLayout,
      FooterLayout,
      SingleBlock,
      TripleBlock,
      NoticeAlert,
      ContactDetailsTable,
      OpeningHoursTable,
      ContactForm,
    },
    data() {
      return {
        contactImage: ImageLoader.loadImageSrc("assets/images/decorators/contact.jpg"),
      }
    },
  }
