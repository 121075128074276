import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5268f58c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.external)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: $props.path,
        target: "_blank"
      }, _toDisplayString($props.title), 9, _hoisted_1))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: $props.path
      }, _toDisplayString($props.title), 9, _hoisted_2))
}