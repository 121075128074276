import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78e86aa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigator_element = _resolveComponent("navigator-element")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([$props.isMenu ? 'menu' : 'bar'])
  }, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.routes, (route) => {
        return (_openBlock(), _createElementBlock("li", { key: route }, [
          _createVNode(_component_navigator_element, {
            label: route.meta.title,
            path: route.path
          }, {
            default: _withCtx(() => [
              ($options.getSubroutes(route).length != 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getSubroutes(route), (subroute) => {
                      return (_openBlock(), _createElementBlock("li", { key: subroute }, [
                        _createVNode(_component_navigator_element, {
                          label: subroute.meta.title,
                          path: subroute.path
                        }, null, 8, ["label", "path"])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["label", "path"])
        ]))
      }), 128))
    ])
  ], 2))
}