
    import ContactClient from "@/assets/scripts/ContactClient";
    import NoticeAlert from "@/components/alerts/Notice.vue";
    import SuccessAlert from "@/components/alerts/Success.vue";
    import ErrorAlert from "@/components/alerts/Error.vue";

    export default {
        name: "ContactForm",
        components: {
            NoticeAlert,
            SuccessAlert,
            ErrorAlert,
        },
        data() {
            return {
                isValidated: false as boolean,
                isSending: false as boolean,
                isSent: false as boolean,
                error: "" as string,
            }
        },
        methods: {
            cancel(): void {
                (this as any).error = "";

                (this as any).$refs.name.value = "";
                (this as any).$refs.mail.value = "";
                (this as any).$refs.message.value = "";

                (this as any).$refs.name.classList.remove("invalid-input");
                (this as any).$refs.mail.classList.remove("invalid-input");
                (this as any).$refs.message.classList.remove("invalid-input");
            },
            validate(): void {
                (this as any).markEmptyInputs();
                (this as any).markInvalidMail();

                if (!(this as any).error) {
                    (this as any).isValidated = true;
                }
            },
            edit(): void {
                (this as any).isValidated = false;
            },
            async send(): Promise<void> {
                (this as any).isValidated = false;
                (this as any).isSending = true;

                const client = new ContactClient();

                // Convert line breaks before sending.
                const htmlMessage: string = (this as any).$refs.message.value.replace(/\n/g, '<br>\n');

                if (await client.postForm((this as any).$refs.name.value, (this as any).$refs.mail.value, htmlMessage)) {
                    (this as any).isSent = true;
                } else {
                    (this as any).error = "Tijdens het verzenden van het bericht is een fout opgetreden. Probeer het later nogmaals.";
                }

                (this as any).isSending = false;
            },
            markEmptyInputs(): void {
                let emptyInputAmount = 0;

                const inputs: HTMLInputElement[] = (this as any).$refs.form.querySelectorAll("input, textarea");
                for (let i = 0; i < inputs.length; i ++) {
                    if (inputs[i].value == "") {
                        inputs[i].classList.add("invalid-input");
                        emptyInputAmount ++;
                    } else {
                        inputs[i].classList.remove("invalid-input");
                    }
                }

                switch (emptyInputAmount) {
                    case 0:
                        (this as any).error = "";
                        break;
                    case 1:
                        (this as any).error = "Vul het gemarkeerde veld in.";
                        break;
                    default:
                        (this as any).error = "Vul alle gemarkeerde velden in.";
                        break;
                }
            },
            markInvalidMail(): void {
                // Only check mail validity once other errors have been resolved.
                if ((this as any).error != "") {
                    return;
                }

                if(!/(.+)@(.+){2,}\.(.+){2,}/.test((this as any).$refs.mail.value)) {
                    (this as any).$refs.mail.classList.add("invalid-input");
                    (this as any).error = "Vul een geldig e-mailadres in.";
                } else {
                    (this as any).$refs.mail.classList.remove("invalid-input");
                    (this as any).error = "";
                }
            },
        },
    };
