
	import HeaderLayout from "@/components/layouts/Header.vue";
	import FooterLayout from "@/components/layouts/Footer.vue";
	import SingleBlock from "@/components/blocks/Single.vue";

	export default {
		name: "AlgemeneVoorwaardenView",
		components: {
			HeaderLayout,
			FooterLayout,
			SingleBlock,
		},
	}
