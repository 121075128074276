
export default {
  name: "OpeningHoursTable",
  methods: {
    runCyclus(): void {
      const currentDate: Date = new Date();
      const row: number = (this as any).convertDayToRow(currentDate.getDay());

      (this as any).resetTimetable();
      (this as any).markTimetableDay(row);
      (this as any).markTimetableTime(currentDate, row);

      // Set timeout to 1 minute.
      setTimeout(() => {
        (this as any).runCyclus();
      }, (60 - currentDate.getSeconds()) * 1000);
    },
    convertDayToRow(day: number): number {
      if (day < 0 || day > 6) {
        console.warn(day + " is not a valid Date day.");
      }

      if (day == 0) {
        return 6;
      } else {
        return day - 1;
      }
    },
    resetTimetable(): void {
      for (let i = 0; i < (this as any).$refs.timetable.rows.length; i ++) {
        (this as any).$refs.timetable.rows[i].cells[0].style.color = "inherit";
        (this as any).$refs.timetable.rows[i].cells[1].style.color = "inherit";
      }
    },
    markTimetableDay(row: number): void {
      (this as any).$refs.timetable.rows[row].cells[0].style.color = "var(--blue-color)";
    },
    markTimetableTime(date: Date, row: number): void {
      const times: string[] = (this as any).$refs.timetable.rows[row].cells[1].innerHTML
        .split((this as any).separator);
      const openingTime: number = parseInt(times[0].replace(/\D/g, ""));
      const closingTime: number = parseInt(times[1].replace(/\D/g, ""));
      const currentTime: number = (date.getHours() * 100) + date.getMinutes();
      
      if (currentTime >= openingTime && currentTime <= closingTime) {
        (this as any).$refs.timetable.rows[row].cells[1].style.color = "var(--green-color)";
      } else {
        (this as any).$refs.timetable.rows[row].cells[1].style.color = "var(--red-color)";
      }
    },
  },
  data() {
    return {
      separator: "-" as string,
    }
  },
  mounted() {
    (this as any).runCyclus();
  },
};
