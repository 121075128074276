
import { RouteRecord } from "vue-router";
import RouteExtractor from "@/assets/scripts/RouteExtractor";
import NavigatorElement from "@/components/navigator/components/Element.vue";

export default {
  name: "NavigatorList",
  props: {
    isMenu: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NavigatorElement,
  },
  methods: {
    getRoutes(): RouteRecord[] {
      const routes: RouteRecord[]
        = RouteExtractor.getNavigatorRoutes((this as any).$router.getRoutes());

      return RouteExtractor.getRoutesByLevel(routes, 2);
    },
    getSubroutes(route: RouteRecord): RouteRecord[] {
      const routes: RouteRecord[]
        = RouteExtractor.getNavigatorRoutes((this as any).$router.getRoutes());

      return RouteExtractor.getChildRoutes(route, routes);
    },
  },
  data() {
    return {
      routes: (this as any).getRoutes() as RouteRecord[],
    }
  },
};
