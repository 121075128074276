import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "feature-section" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_layout = _resolveComponent("header-layout")!
  const _component_warning_alert = _resolveComponent("warning-alert")!
  const _component_single_block = _resolveComponent("single-block")!
  const _component_footer_layout = _resolveComponent("footer-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_layout, {
      title: "Zoekresultaten",
      displaySearchForm: false
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_single_block, null, {
          default: _withCtx(() => [
            _createVNode(_component_warning_alert, null, {
              default: _withCtx(() => [
                _createTextVNode(" De zoekfunctie is in ontwikkeling en is daarom tijdelijk uitgeschakeld. Excuses voor het ongemak. ")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_footer_layout)
  ], 64))
}