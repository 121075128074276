import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node = _resolveComponent("node")!

  return (_openBlock(), _createBlock(_component_node, {
    class: "node",
    route: $options.getIndexRoute(),
    routes: $data.routes
  }, null, 8, ["route", "routes"]))
}