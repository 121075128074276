class ColorConverter {
  static hexToRgba(hex: string, opacity: number) {
    const hexWarning = hex + " is not a valid hex color code format.";
    const opacityWarning = opacity + " is not a valid opacity value.";
    hex = hex.replace(/ /g, "");

    if (hex[0] != "#") {
      console.warn(hexWarning);
      return null;
    }
    
    let r, g, b;

    if (hex.length == 4) {
      r = parseInt("0x" + hex[1] + hex[1]);
      g = parseInt("0x" + hex[2] + hex[2]);
      b = parseInt("0x" + hex[3] + hex[3]);
    } else if (hex.length == 7) {
      r = parseInt("0x" + hex[1] + hex[2]);
      g = parseInt("0x" + hex[3] + hex[4]);
      b = parseInt("0x" + hex[5] + hex[6]);
    } else {
      console.warn(hexWarning);
      return null;
    }

    if (isNaN(r) || isNaN(g) || isNaN(b)) {
      console.warn(hexWarning);
      return null;
    }

    if (opacity >= 0 && opacity <= 1) {
      return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
    } else {
      console.warn(opacityWarning);
      return "rgba(" + r + "," + g + "," + b + ",1)";
    }
  }
}

export default ColorConverter;
