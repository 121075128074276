import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ece07d62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.routes, (route) => {
      return (_openBlock(), _createElementBlock("li", { key: route }, [
        (route.path == $data.currentRoute.path)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(route.meta.title), 1))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: route.path
            }, _toDisplayString(route.meta.title), 9, _hoisted_2))
      ]))
    }), 128))
  ]))
}