
export default {
  name: "SearchForm",
  props: {
    isCompact: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    adjustform(): void {
      if ((this as any).isOneline()) {
        (this as any).$refs.form.classList.add("form-oneline");
        (this as any).$refs.form.classList.remove("form-multiline");
      } else {
        (this as any).$refs.form.classList.add("form-multiline");
        (this as any).$refs.form.classList.remove("form-oneline");
      }
    },
    isOneline(): boolean {
      return (this as any).$refs.input.getBoundingClientRect().bottom
        == (this as any).$refs.button.getBoundingClientRect().bottom;
    },
    search(): void {
      (this as any).$router.push("/zoekresultaten");
    }
  },
  mounted() {
    (this as any).adjustform();
    window.addEventListener("resize", (this as any).adjustform);
  },
  beforeUnmount() {
    window.removeEventListener("resize", (this as any).adjustform);
  },
};
