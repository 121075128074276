import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d7b8bfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "input",
  type: "text",
  placeholder: "Zoekopdracht..."
}
const _hoisted_2 = ["onclick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass({ 'form-compact': $props.isCompact }),
    ref: "form"
  }, [
    _createElementVNode("input", _hoisted_1, null, 512),
    _createElementVNode("button", {
      onclick: $options.search,
      ref: "button",
      class: "button-inline",
      type: "button"
    }, "Zoeken", 8, _hoisted_2)
  ], 2))
}