
    import Navigator from "@/components/navigator/Navigator.vue";
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
    import SearchForm from "@/components/forms/Search.vue";
    
    export default {
        name: "HeaderLayout",
        props: {
            title: String,
            subtitle: String,
            backgroundImage: String,
            displayNavigator: {
                type: Boolean,
                default: true,
            },
            displayBreadcrumb: {
                type: Boolean,
                default: true,
            },
            displaySearchForm: {
                type: Boolean,
                default: true,
            }
        },
        components: {
            Navigator,
            Breadcrumb,
            SearchForm,
        },
        methods: {
            setBackground(): void {
                if ((this as any).backgroundImage != null) {
                    (this as any).$refs.titleSection.style.backgroundImage
                        = "url('" + (this as any).backgroundImage + "')";
                    (this as any).$refs.titleSection.style.textShadow
                        = "var(--outline-text-shadow)";
                }
            },
        },
        mounted() {
            (this as any).setBackground();
        },
    };
